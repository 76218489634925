'use client'

import { Button } from '@/web/components/ui/button'
import {
  Carousel,
  CarouselContent,
  type CarouselApi,
} from '@/web/components/ui/carousel'
import { cn } from '@/web/libs/utils'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import * as React from 'react'

export function CarouselWithButtons({
  children,
  className,
  contentClassName,
  opts,
}: React.PropsWithChildren<{
  className?: string
  contentClassName?: string
}> &
  Pick<React.ComponentProps<typeof Carousel>, 'opts'>) {
  const [api, setApi] = React.useState<CarouselApi>()
  const [current, setCurrent] = React.useState(0)
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    if (!api) {
      return
    }

    setCount(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap() + 1)

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1)
    })
  }, [api, setCurrent, setCount])

  return (
    <>
      <Carousel
        opts={{
          align: 'start',
          loop: true,
          ...(opts || {}),
        }}
        className={cn('w-full max-w-5xl mx-auto', className)}
        setApi={setApi}>
        <CarouselContent className={contentClassName}>
          {children}
        </CarouselContent>
        {count > 1 ? (
          <div className='flex justify-between mt-4'>
            <Button
              variant='outline'
              theme='secondary'
              size='circle'
              className='absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2'
              disabled={!api?.canScrollPrev()}
              onClick={() => api?.scrollPrev()}>
              <ChevronLeft className='h-4 w-4' />
              <span className='sr-only'>Previous</span>
            </Button>
            <Button
              variant='outline'
              theme='secondary'
              size='circle'
              className='absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2'
              disabled={!api?.canScrollNext()}
              onClick={() => api?.scrollNext()}>
              <ChevronRight className='h-4 w-4' />
              <span className='sr-only'>Next</span>
            </Button>
          </div>
        ) : null}
      </Carousel>
      {count > 1 ? (
        <div className='flex justify-center mt-4 space-x-2'>
          {Array.from({ length: count }).map((_, index) => (
            <Button
              key={index}
              variant={index === current - 1 ? 'default' : 'outline'}
              theme={index === current - 1 ? 'primary' : 'secondary'}
              size='circle'
              className='w-2 h-2 rounded-full border'
              onClick={() => api?.scrollTo(index)}>
              <span className='sr-only'>Go to slide {index + 1}</span>
            </Button>
          ))}
        </div>
      ) : null}
    </>
  )
}
