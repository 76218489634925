import { EVENT } from '@/shared/constants/analytics'
import {
  PRICING_TIER_DATA,
  PricingPeriod,
  PricingTierId,
} from '@/shared/constants/pricing'
import {
  getSections,
  getTiers,
  sortAndFilterSectionFeatures,
} from '@/shared/utils/pricing'
import { getMonthlyPrice } from '@/shared/utils/stripe'
import { getTierId, isFreeTrial } from '@/shared/utils/subscription'
import { CarouselWithButtons } from '@/web/components/CarouselWithButtons'
import { PricingPlanButton } from '@/web/components/PricingPlanButton'
import { Badge } from '@/web/components/ui/badge'
import { CarouselItem } from '@/web/components/ui/carousel'
import { Tabs, TabsList, TabsTrigger } from '@/web/components/ui/tabs'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/web/components/ui/tooltip'
import { ROUTE } from '@/web/constants/router'
import { cn } from '@/web/libs/utils'
import { stripe } from '@/web/singletons/stripe'
import { track } from '@/web/utils/analytics'
import { api } from '@/web/utils/api'
import type { IntegrationName } from '@prisma/client'
import _ from 'lodash'
import { CheckIcon } from 'lucide-react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'

export const PricingPlans = ({
  integrationName,
  // type,
  isStatic,
}: {
  integrationName?: IntegrationName
  // type?: 'simple'
  isStatic?: boolean
}) => {
  const [pricePeriod, setPricePeriod] = useState<PricingPeriod>(
    PricingPeriod.Monthly
  )

  const pricesQueryResult = api.subscription.prices.useQuery()
  const prices = pricesQueryResult.data

  const session = useSession()

  const subscriptionInfoQueryResult = api.subscription.info.useQuery(
    undefined,
    {
      enabled: session.status === 'authenticated',
    }
  )
  const signupDate = session.data?.user?.createdAt
    ? new Date(session.data.user.createdAt)
    : undefined
  const subscription = subscriptionInfoQueryResult.data
  const currentTierId = getTierId({
    subscription,
    role: session.data?.user?.role,
  })
  const isTrial = isFreeTrial({
    subscription,
    signupDate,
  })

  const cancelSubscriptionMutationResult = api.subscription.cancel.useMutation({
    onSuccess: () => {
      subscriptionInfoQueryResult.refetch()
      toast.success('Subscription canceled successfully')
    },
    onError: () => {
      toast.error('Failed to cancel subscription')
    },
  })

  const createCheckoutSessionMutationResult =
    api.subscription.createCheckoutSession.useMutation({
      onSuccess: async (data, variables) => {
        if (data && 'url' in data && data.url) {
          track(EVENT.ORDER_STARTED, {
            ...variables,
          })
          window.location.href = data.url
          return
        }

        if ('requiresAction' in data && data.requiresAction) {
          const { paymentIntentClientSecret } = data
          if (!stripe) {
            toast.error('Stripe is not available. Please try again later.')
            return
          }
          if (!paymentIntentClientSecret) {
            toast.error('Payment authentication failed. Please try again.')
            return
          }

          const result = await stripe?.confirmCardPayment(
            paymentIntentClientSecret
          )
          if (result?.error) {
            // Show error to your customer
            toast.error('Payment authentication failed. Please try again.')
          } else {
            // Payment succeeded
            toast.success('Subscription updated successfully!')
          }
          return
        }

        if ('error' in data && data.error) {
          toast.error(data.error)
          if ('billingPortalUrl' in data && data.billingPortalUrl) {
            // Redirect user to update payment method
            window.location.href = data.billingPortalUrl
            return
          }
        }

        toast.success('Subscription updated successfully!')
        subscriptionInfoQueryResult.refetch()
      },
      onError: err => {
        toast.error('Failed to create checkout session', {
          description: err.message,
        })
      },
    })

  const router = useRouter()
  // Show toast when checkout is created/canceled
  useEffect(() => {
    if (
      router.query?.success === 'true' &&
      subscriptionInfoQueryResult.isSuccess
    ) {
      toast.success('Payment successful')
      track(EVENT.ORDER_COMPLETED, {
        name: integrationName,
        pricingTierId: currentTierId,
        revenue: subscription?.items?.data?.[0]?.price?.unit_amount,
        currency: subscription?.items?.data?.[0]?.price?.currency,
      })

      // Remove the query parameters
      router.replace(router.pathname, undefined, { shallow: true })
    } else if (router.query?.canceled === 'true') {
      toast.error('Payment canceled')
      track(EVENT.ORDER_CANCELLED, {
        name: integrationName,
        pricingTierId: currentTierId,
      })
    }
  }, [router.query, subscriptionInfoQueryResult.isSuccess])

  const onClickPricingTierButton = (pricingTierId: PricingTierId) => {
    if (PRICING_TIER_DATA[pricingTierId].isContactUsOnly) {
      const body = `Hi Pulse Enterprise Team,

I’m interested in learning more about your services. Here are my company details.

Name:
Website:
Industry:

Thanks,${session.data?.user?.name ? `\n\n${session.data?.user?.name}` : ''}`

      // const url = new URL('mailto:enterprise@usepulse.ai')
      // url.searchParams.set('subject', 'Enterprise Request')
      // url.searchParams.set('body', body)
      const url = `mailto:enterprise@usepulse.ai?subject=Enterprise Request&body=${encodeURIComponent(
        body
      )}`

      console.log('url', url.toString())
      router.push(url.toString())
      return
    }

    if (session.status === 'authenticated') {
      if (isStatic) {
        router.push(ROUTE.SETTINGS_SUBSCRIPTION)
        return
      }

      if (pricingTierId === PricingTierId.FREE) {
        cancelSubscriptionMutationResult.mutate()
        return
      }

      if (!prices) {
        throw new Error('Prices not found')
      }

      const priceId = prices[pricingTierId]?.[pricePeriod].id
      if (!priceId) {
        console.error('Price id not found', {
          pricingTierId,
          pricePeriod,
          prices,
        })
        throw new Error('Price id not found')
      }

      createCheckoutSessionMutationResult.mutate({
        priceId,
        redirectUrl: window.location.origin + window.location.pathname,
      })
    } else {
      router.push(ROUTE.SIGN_IN)
    }
  }

  const isLoading =
    subscriptionInfoQueryResult.isInitialLoading ||
    cancelSubscriptionMutationResult.isLoading ||
    createCheckoutSessionMutationResult.isLoading ||
    session.status === 'loading'

  const tiers = getTiers(integrationName)
  const sections = getSections(integrationName)

  return (
    <>
      <Tabs
        defaultValue={pricePeriod}
        onValueChange={value => setPricePeriod(value as PricingPeriod)}
        className='mx-auto text-center'>
        <TabsList>
          {Object.entries(PricingPeriod).map(([period, label]) => (
            <TabsTrigger key={period} value={period}>
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      {/* Carousel */}
      <div className='px-4'>
        <CarouselWithButtons
          className={cn(
            'flex flex-wrap mx-auto justify-center max-w-full px-8'
            // type !== 'simple' && 'lg:hidden'
          )}
          contentClassName='pt-3'
          opts={{
            align: 'start',
            loop: false,
          }}>
          {tiers.map(pricingTierId => {
            const {
              isMostPopular,
              isContactUsOnly,
              name,
              monthlyPrice,
              integrationSpecificData,
              description,
              isPerUser,
            } = PRICING_TIER_DATA[pricingTierId]

            const price = prices?.[pricingTierId]?.[pricePeriod]
              ? getMonthlyPrice(prices[pricingTierId][pricePeriod], pricePeriod)
              : monthlyPrice?.[pricePeriod] ?? 0

            return (
              <CarouselItem
                key={pricingTierId}
                className='sm:basis-1/2 lg:basis-1/3 2xl:basis-1/4 items-stretch'>
                <div
                  className={cn(
                    'rounded-xl p-8 relative h-full',
                    isMostPopular
                      ? ' bg-gray-400/5 outline-gradient outline-gradient-sm'
                      : 'ring-1 ring-inset ring-gray-200'
                  )}>
                  {isMostPopular ? (
                    <Badge
                      theme='gradient'
                      className='absolute top-0 inset-x-0 mx-auto -translate-y-1/2 z-10 w-fit'>
                      Most Popular
                    </Badge>
                  ) : null}
                  <h6
                    id={pricingTierId}
                    className='leading-none h-6 line-clamp-1'>
                    {(integrationName &&
                      integrationSpecificData?.[integrationName]?.name) ??
                      name}
                  </h6>
                  <small
                    className='text-muted-foreground block h-8 line-clamp-2'
                    suppressHydrationWarning>
                    {(integrationName &&
                      integrationSpecificData?.[integrationName]
                        ?.description) ??
                      description}
                  </small>

                  <div className='mb-1 mt-2 flex items-start gap-x-4 justify-between text-gray-900'>
                    <span className='flex items-baseline gap-x-1 text-gray-900'>
                      <span className='text-4xl font-bold text-nowrap'>
                        {isContactUsOnly ? 'Custom' : <>${price}</>}
                      </span>
                      {monthlyPrice || !isContactUsOnly ? (
                        <span className='text-sm font-semibold'>
                          {isPerUser ? '/user' : ''}
                          /month
                        </span>
                      ) : null}
                    </span>
                    {monthlyPrice?.Monthly &&
                    pricePeriod === PricingPeriod.Annually ? (
                      <div className='flex flex-col gap-1.5 items-end justify-between'>
                        <Badge size='2xs' theme='success'>
                          {_.round(
                            (1 - monthlyPrice.Annually / monthlyPrice.Monthly) *
                              100
                          )}
                          % off
                        </Badge>
                        <small className='text-muted-foreground'>
                          ${monthlyPrice.Annually * 12}/year{' '}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <PricingPlanButton
                    isLoading={isLoading}
                    pricingTierId={pricingTierId}
                    currentPricingTierId={currentTierId}
                    isTrial={isTrial}
                    onClick={() => onClickPricingTierButton(pricingTierId)}
                    isStatic={isStatic}
                  />

                  <ul
                    role='list'
                    className='mt-10 space-y-4 text-sm leading-6 text-gray-900'>
                    {sections.map(section => (
                      <li key={section.name}>
                        <ul role='list' className='space-y-4'>
                          {sortAndFilterSectionFeatures({
                            features: section.features,
                            integrationName,
                          }).map(feature => {
                            if (!feature.tiers[pricingTierId]) {
                              return null
                            }

                            const hasTierText =
                              typeof feature.tiers[pricingTierId] !== 'boolean'
                            const featureName =
                              (integrationName &&
                                feature.integrationSpecificData?.[
                                  integrationName
                                ]?.name) ??
                              feature.name

                            return (
                              <Tooltip key={feature.name}>
                                <TooltipTrigger asChild>
                                  <li
                                    key={feature.name}
                                    className='flex gap-x-3'>
                                    <CheckIcon
                                      className='text-primary h-6 w-5 flex-none'
                                      aria-hidden='true'
                                    />
                                    <div className='text-sm leading-6 line-clamp-1  border-b border-dashed border-border/75'>
                                      {hasTierText ? (
                                        <span className='text-gradient'>
                                          {feature.tiers[pricingTierId]}{' '}
                                        </span>
                                      ) : null}
                                      <span className=' text-gray-500'>
                                        {hasTierText
                                          ? featureName.toLowerCase()
                                          : featureName
                                              .slice(0, 1)
                                              .toUpperCase() +
                                            featureName.slice(1).toLowerCase()}
                                      </span>
                                    </div>
                                  </li>
                                </TooltipTrigger>
                                <TooltipContent>
                                  {feature.description}
                                </TooltipContent>
                              </Tooltip>
                            )
                          })}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </CarouselItem>
            )
          })}
        </CarouselWithButtons>
      </div>
      {/*
      {type !== 'simple' && (
        <PricingPlansTable
          tiers={tiers}
          sections={sections}
          onClickPricingTierButton={onClickPricingTierButton}
          integrationName={integrationName}
          pricePeriod={pricePeriod}
          isLoading={isLoading}
          currentTierId={currentTierId}
          isTrial={isTrial}
          isStatic={isStatic}
          prices={prices}
        />
      )} */}
    </>
  )
}
