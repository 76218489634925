import { PRICING_TIER_DATA, PricingTierId } from '@/shared/constants/pricing'
import { isUpgrading } from '@/shared/utils/subscription'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/web/components/ui/alert-dialog'
import { Button, type ButtonProps } from '@/web/components/ui/button'
import { useSession } from 'next-auth/react'

export const PricingPlanButton = ({
  pricingTierId,
  currentPricingTierId,
  isLoading,
  isTrial,
  onClick,
  isStatic,
  ...props
}: ButtonProps & {
  pricingTierId: PricingTierId
  currentPricingTierId: PricingTierId | null
  isTrial: boolean
  isLoading: boolean
  isStatic?: boolean
}) => {
  const session = useSession()
  const isCurrent = !isStatic && pricingTierId === currentPricingTierId
  const isUpgrade =
    !isStatic && isUpgrading(currentPricingTierId, pricingTierId)
  const isDisabled =
    PRICING_TIER_DATA[pricingTierId].isComingSoon ||
    (isCurrent && !isStatic) ||
    isLoading
  const isDowngrade =
    !isStatic &&
    currentPricingTierId !== pricingTierId &&
    !isUpgrading(currentPricingTierId, pricingTierId)
  const isContactUsOnly =
    PRICING_TIER_DATA[pricingTierId].isContactUsOnly ?? false

  return (
    <AlertDialog open={isUpgrade ? false : undefined}>
      <AlertDialogTrigger
        asChild
        onClick={session.status === 'authenticated' ? undefined : onClick}>
        <Button
          aria-describedby={pricingTierId}
          size='sm'
          variant={
            PRICING_TIER_DATA[pricingTierId].isMostPopular
              ? 'default'
              : 'outline'
          }
          disabled={isDisabled}
          theme={
            PRICING_TIER_DATA[pricingTierId].isMostPopular
              ? 'premium'
              : 'primary'
          }
          className='w-full'
          onClick={!isDowngrade ? onClick : undefined}
          state={isLoading && !isDisabled ? 'loading' : undefined}
          {...props}>
          {isContactUsOnly
            ? 'Contact us'
            : PRICING_TIER_DATA[pricingTierId].isComingSoon
            ? 'Coming soon'
            : session.status !== 'authenticated' || isStatic
            ? 'Get Started for Free'
            : isCurrent
            ? isTrial
              ? 'Trialing'
              : 'Current plan'
            : PRICING_TIER_DATA[pricingTierId].isComingSoon
            ? 'Coming soon'
            : isDowngrade
            ? 'Downgrade'
            : pricingTierId === PricingTierId.FREE
            ? 'Choose plan'
            : 'Buy plan'}
        </Button>
      </AlertDialogTrigger>
      {session.status === 'authenticated' && isDowngrade && (
        <AlertDialogContent>
          <>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Are you sure you want to downgrade to{' '}
                {PRICING_TIER_DATA[pricingTierId].name}?
              </AlertDialogTitle>
              <AlertDialogDescription>
                This will cancel your current subscription.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                theme={isUpgrade ? 'premium' : 'destructive'}
                onClick={onClick}>
                Downgrade to {PRICING_TIER_DATA[pricingTierId].name}
              </AlertDialogAction>
            </AlertDialogFooter>
          </>
        </AlertDialogContent>
      )}
    </AlertDialog>
  )
}
