export enum EVENT {
  // Auth
  SIGNUP = 'Signup',
  LOGIN = 'Login',
  LINK_ACCOUNT = 'Link Account',
  UPDATE_USER = 'Update User',
  LOGOUT = 'Logout',

  // Integrations
  INTEGRATING = 'Integrating',
  INTEGRATED = 'Integrated',
  ERROR_INTEGRATING = 'Error Integrating',
  SUCCESS_INTEGRATING = 'Success Integrating',
  UPDATE_INTEGRATION_SETTINGS = 'Update Integration Settings',
  DELETE_INTEGRATION = 'Delete Integration',

  // Tasks
  COMPLETE_TASK = 'Complete Task',
  UNCOMPLETE_TASK = 'Uncomplete Task',
  UPDATE_TASK_STATUS = 'Update Task Status',
  SUBMIT_TASK_FEEDBACK = 'Submit Task Feedback',
  CLICK_UPDATE_TASKS_BUTTON = 'Click Update Tasks Button',
  READ_TASK = 'Read Task',
  DELETE_TASK = 'Delete Task',
  CREATE_TASK = 'Create Task',
  UPDATE_TASK = 'Update Task',
  SET_TASK_PRIORITY = 'Set Task Priority',
  SCHEDULE_TASK = 'Schedule Task',
  UNSCHEDULE_TASK = 'Unschedule Task',
  RESCHEDULE_CONFLICTING_TASKS = 'Reschedule Conflicting Tasks',
  CARRY_OVER_TASKS = 'Carry Over Tasks',
  ASSIGN_TASK = 'Assign Task',
  UNASSIGN_TASK = 'Unassign Task',

  // Actions
  OPEN_ACTION = 'Open Action',
  COMPLETE_ACTION = 'Complete Action',
  CANCEL_ACTION = 'Cancel Action',

  // Generation
  GENERATE_COMMENTS = 'Generate Comments',
  GENERATE_COMMENT = 'Generate Comment',
  GENERATE_REPLY = 'Generate Reply',
  GENERATE_REPLIES = 'Generate Replies',
  GENERATE_KEYWORD_MATCH_REPLIES = 'Generate Keyword Match Replies',
  GENERATE_KEYWORD_MATCH_REPLY = 'Generate Keyword Match Reply',

  // Subscription
  ORDER_STARTED = 'Order Started',
  ORDER_COMPLETED = 'Order Completed',
  ORDER_CANCELLED = 'Order Cancelled',
  SUBSCRIPTION_UPDATED = 'Subscription Updated',
  SUBSCRIPTION_UPDATE_FAILED = 'Subscription Update Failed',

  // Settings
  UPDATE_SETTINGS = 'Update Settings',
  CHANGE_PASSWORD = 'Change Password',

  // Email
  SEND_EMAIL = 'Send Email',
  SEND_EMAIL_FAILURE = 'Send Email Failure',

  // Waitlist
  JOIN_WAITLIST = 'Join Waitlist',
}
